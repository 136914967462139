import React from "react";

import service1 from '../img/service/fret_maritime.svg';
import service2 from '../img/service/Terrestre.svg';
import service3 from '../img/service/fret_aerien.svg';
import service4 from '../img/service/transit_et_dedouanement.svg';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        detection: {
            order: ['htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
            caches: ["cookie"],
        },
        backend: {
            loadPath: '/locale/{{lng}}/translation.json',
        }
    });

function Service() {
    const { t } = useTranslation();

    return (
        <div>
              

            <div className="service_area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section_title mb-50 text-center">
                                <h3>{t('NosServices')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="service_active owl-carousel">
                                <div className="single_service">
                                    <a href="/services?service=fret-maritime" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="thumb">
                                            <img src={service1} alt="Service Fret Maritime" />
                                        </div>
                                        <div className="service_info">
                                            <h3 datal-i18n="titleservice1">{t('Fret maritime')}</h3>
                                            <p datal-i18n="descriptionservice1">{t('desc1service1')}</p>
                                        </div>
                                    </a>
                                </div>

                                <div className="single_service">
                                    <a href="/services?service=terrestre" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="thumb">
                                            <img src={service2} alt="Service Terrestre" />
                                        </div>
                                        <div className="service_info">
                                            <h3 datal-i18n="titleservice2">{t('terrestre')}</h3>
                                            <p datal-i18n="descriptionservice2">{t('desc1service2')}</p>
                                        </div>
                                    </a>
                                </div>

                                <div className="single_service">
                                    <a href="/services?service=fret-aerien" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="thumb">
                                            <img src={service3} alt="Service Fret Aérien" />
                                        </div>
                                        <div className="service_info">
                                            <h3 datal-i18n="titleservice3">{t('Fret Aerien')}</h3>
                                            <p datal-i18n="descriptionservice3">{t('desc1service3')}</p>
                                        </div>
                                    </a>
                                </div>

                                <div className="single_service">
                                    <a href="/services?service=douane" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="thumb">
                                            <img src={service4} alt="Service Dédouanement" />
                                        </div>
                                        <div className="service_info">
                                            <h3 datal-i18n="titleservice4">{t('Dédouanement')}</h3>
                                            <p datal-i18n="descriptionservice4">{t('descriptionservice4')}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
