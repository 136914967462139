import React, {useState} from "react";
import { Helmet } from 'react-helmet';
import Transportaion from "../Components/Transportaion";
import About from"../Components/About";
import Slider from "../Components/slider";
import Service from "../Components/service";
import Contact from "../Components/contact";
import Estimate from "../Components/Estimate";
import Location from "../Components/location";
import Footer from "../Components/footer";
function Home(){
    return(
<div>
<Helmet>
                <title>ITTS- Transitaire et Logistique</title>
                <meta name="description" content="Découvrez les services de transit et logistique d'ITTS." />
                <meta name="keywords" content="ITTS, transitaire, logistique, services de transit, Transport international Tunisie, Import/export, Fret terrestre, Fret aérien, Fret maritime, Dédouanement,Gestion de fret" />
            </Helmet>

<Slider/>
<Transportaion/>
<Service/>
<Contact/>
<About/>
<Estimate/>
<Location/>
<Footer/>

</div>
    
     )

}
export default Home;