import React, { useEffect, useState } from "react";
import logo from '../img/logo.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Récupérer la langue de localStorage
    const savedLanguage = localStorage.getItem('language') || 'fr';
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    navigate(window.location.pathname); // Naviguer vers la même page pour appliquer la langue
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <header>
        <div className="header-area">
          <div className="header-top_area d-none d-lg-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4">
                  <div className="logo">
                    <a href="/">
                      <img src={logo} alt="Logo" />
                    </a>
                  </div>
                </div>
                <div className="col-xl-8 col-md-8">
                  <div className="header_right d-flex align-items-center justify-content-end">
                    <div className="short_contact_list">
                      <ul>
                        <li>
                          <a href="mailto:contact@itts.com.tn?subject=Bonjour%20World&body=This%20is%20the%20email%20body">
                            <i className="fa fa-envelope"></i> contact@itts.com.tn
                          </a>
                        </li>
                        <li>
                          <a href="tel:+21671449033">
                            <i className="fa fa-phone"></i> (+216) 71 449 033
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="sticky-header" className="main-header-area">
            <div className="container">
              <div className="header_bottom_border">
                <div className="row align-items-center">
                <div className="col-12 d-block d-lg-none">
  <div className="d-flex align-items-center justify-content-between">
    <div className="logo">
      <a href="/">
        <img src={logo} alt="Logo" />
      </a>
    </div>
    <button className="menu-toggle" onClick={toggleMenu}>
      {isMenuOpen ? '✖' : '☰'}
    </button>
  </div>
</div>
                  <div className="col-xl-9 col-lg-9">
                    <div className="main-menu">
                      
                      <nav className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                        <ul id="navigation">
                          <li><a href="/">{t('Home')}</a></li>
                          <li><a href="/services">{t('Services')}</a></li>
                          <li><a href="/about">{t('About')}</a></li>
                          <li><a href="/contact">{t('Contact')}</a></li>
                        </ul>
                        <div className="lang-selector-container">
                          <select
                            className="lang-selector"
                            onChange={(e) => changeLanguage(e.target.value)}
                            value={localStorage.getItem('language') || 'fr'}
                          >
                            <option value="fr">FR</option>
                            <option value="en">EN</option>
                          </select>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
