import React from "react";

import aboutImg from '../img/about/about.png';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        detection: {
            order: ['htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
            caches: ["cookie"],
        },
        backend: {
            loadPath: '/locale/{{lng}}/translation.json',
        }
    });

function About() {
    const { t } = useTranslation();
    return (
        <div>
        

            <div className="chose_area ">
                <div className="container">
                    <div className="features_main_wrap">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-5 col-md-6">
                                <div className="about_image">
                                    <img src={aboutImg} alt="À propos de ITTS" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="features_info">
                                    <h3>{t('About')}</h3>
                                    <p>{t('descAbout')}</p>
                                    <div className="about_btn">
                                        <a className="boxed-btn3-line" href="/about">{t('btnabout')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
