import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
export const Estimate = () => {

  const [Name, from_name] = useState('');
  const [Email, from_email] = useState('');
  const [Type, type_marchandise] = useState('');
  const [Dime, dimension] = useState('');
  const [aerien, fret_aerien] = useState('');
  const [Ville1, ville1] = useState('');
  const [ville2, villed] = useState('');
  const [Message, message] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleChangeName = (e) => from_name(e.target.value);
  const handleChangeEmail = (e) => from_email(e.target.value);
  const handleChangeType = (e) => type_marchandise(e.target.value);
  const handleChangeDime = (e) => dimension(e.target.value);
  const handleChangeAerien = (e) => fret_aerien(e.target.value);
  const handleChangeVille1 = (e) => ville1(e.target.value);
  const handleChangeVille2 = (e) => villed(e.target.value);
  const handleChangeMessage = (e) => message(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bjtio3g', 'template_ah13emu', e.target, {
      publicKey: 'wp4qxQcdMNc3hnPWn',
    })
   
      .then((result) => {
        setFeedback('Email envoyé avec succès !');
        from_name('');
        from_email('');
        type_marchandise('');
        dimension('');
        fret_aerien('');
        ville1('');
        villed('');
        message('');
      }, (error) => {
        setFeedback('Une erreur est survenue, veuillez réessayer.');
        console.log(error.text);
      });
  };




  const { t } = useTranslation();
  return (

<div>

<div class="Estimate_area overlay">
<div class="container">
<div class="row">
<div class="col-xl-4 col-lg-4 col-md-5">
<div class="Estimate_info">
<h3 >{t('titleEstimation')}</h3>

<p><br/></p>

<a href="tel:+21671449033" class="boxed-btn3">(+216) 71 449 033</a>
</div>
</div>
<div class="col-xl-8 col-lg-8 col-md-7">
<div class="form">
<form onSubmit={handleSubmit}>
<div class="row">
<div class="col-xl-6">
<div class="input_field">
<input  name="from_name"  
 value={Name}
 onChange={handleChangeName}
type="text"  placeholder={t('nom')}required/>

</div>
</div>
<div class="col-xl-6">
<div class="input_field">
<input  name="from_email"
 value={Email}
 onChange={handleChangeEmail}
type="Email"  placeholder="Email"required/>
</div>
</div>

<div class="col-xl-6">
<div class="input_field">
<select class="wide" name="type_marchandise"

value={Type}
onChange={handleChangeType}
required>
<option data-display="Product type" datal-i18n="Typemarchandise" >{t('Typemarchandise')}</option>
<option value="Marchandise standard" datal-i18n="type1">{t('type1')}</option>
<option value="Effets personnel "datal-i18n="type2">{t('type2')}</option>
<option value="Inflammable/dangeureux"datal-i18n="type3">{t('type3')}</option>
<option value="Perisable"datal-i18n="type4">{t('type4')}</option>
</select>
</div>
</div>

<div class="col-xl-6">
<div class="input_field">
<select class="wide" name="dimension"
value={Dime}
onChange={handleChangeDime}
>
<option data-display="Product size"  >Dimension</option>
<option value="Conteneur 20" >{t('Conteneur20')}</option>
<option value="Conteneur 40">{t('Conteneur40')}</option>
<option value="340hc" >40hc</option>
<option value="20 refeer" >20 refeer</option>
<option value="40 refeer">40 refeer</option>
<option value="Groupage">Groupage</option>
</select>
</div>
</div>

<div class="col-xl-12">
    <div class="input_field">

    <input  
    
    name="fret_aerien"
    
    value={aerien}
onChange={handleChangeAerien}
    type="text"  placeholder={t('colis')}/>
    </div>
    </div>


    <div class="col-xl-6">
<div class="input_field">
<select class="wide" name="ville1"
value={Ville1}
onChange={handleChangeVille1}
>
        <option data-display="City of departure">{t('VilleDeDepart')}</option>
        
        <option value="Afghanistan">Afghanistan</option>
        <option value="Afrique">Afrique du Sud</option>
        <option value="Albanie">Albanie</option>
        <option value="Algérie">Algérie</option>
        <option value="Allemagne">Allemagne</option>
        <option value="yougoslave de Macédoine">Ancienne République yougoslave de Macédoine</option>
        <option value="Andorre">Andorre</option>
        <option value="Angola">Angola</option>
        <option value="Anguilla">Anguilla</option>
        <option value="Antarctique">Antarctique</option>
        <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
        <option value="Antilles">Antilles néerlandaises</option>
        <option value="saoudite">Arabie saoudite</option>
        <option value="AArgentineR">Argentine</option>
        <option value="AArménieM">Arménie</option>
        <option value="AWAruba">Aruba</option>
        <option value="Australie">Australie</option>
        <option value="Autriche">Autriche</option>
        <option value="Azerbaïdjan">Azerbaïdjan</option>
        <option value="BS">Bahamas</option>
        <option value="BH">Bahreïn</option>
        <option value="BD">Bangladesh</option>
        <option value="BB">Barbade</option>
        <option value="BE">Belgique</option>
        <option value="BZ">Belize</option>
        <option value="BJ">Bénin</option>
        <option value="BM">Bermudes</option>
        <option value="BT">Bhoutan</option>
        <option value="BY">Biélorussie</option>
        <option value="BO">Bolivie</option>
        <option value="BA">Bosnie-et-Herzégovine</option>
        <option value="BW">Botswana</option>
        <option value="BR">Brésil</option>
        <option value="BN">Brunei Darussalam</option>
        <option value="BG">Bulgarie</option>
        <option value="BF">Burkina Faso</option>
        <option value="BI">Burundi</option>
        <option value="KH">Cambodge</option>
        <option value="CM">Cameroun</option>
        <option value="CA">Canada</option>
        <option value="CV">Cap-Vert</option>
        <option value="CL">Chili</option>
        <option value="CN">Chine</option>
        <option value="CY">Chypre</option>
        <option value="CO">Colombie</option>
        <option value="KM">Comores</option>
        <option value="CG">Congo</option>
        <option value="CR">Costa Rica</option>
        <option value="CI">Côte d'Ivoire</option>
        <option value="HR">Croatie</option>
        <option value="CU">Cuba</option>
        <option value="DK">Danemark</option>
        <option value="DJ">Djibouti</option>
        <option value="DM">Dominique</option>
        <option value="SV">El Salvador</option>
        <option value="AE">Émirats arabes unis</option>
        <option value="EC">Équateur</option>
        <option value="ER">Érythrée</option>
        <option value="ES">Espagne</option>
        <option value="EE">Estonie</option>
        <option value="FM">États fédérés de Micronésie</option>
        <option value="US">États-Unis</option>
        <option value="ET">Éthiopie</option>
        <option value="FJ">Fidji</option>
        <option value="FI">Finlande</option>
        <option value="FR">France</option>
        <option value="GA">Gabon</option>
        <option value="GM">Gambie</option>
        <option value="GE">Géorgie</option>
        <option value="GS">Géorgie du Sud-et-les Îles Sandwich du Sud</option>
        <option value="GH">Ghana</option>
        <option value="GI">Gibraltar</option>
        <option value="GR">Grèce</option>
        <option value="GD">Grenade</option>
        <option value="GL">Groenland</option>
        <option value="GP">Guadeloupe</option>
        <option value="GU">Guam</option>
        <option value="GT">Guatemala</option>
        <option value="GN">Guinée</option>
        <option value="GQ">Guinée équatoriale</option>
        <option value="GW">Guinée-Bissau</option>
        <option value="GY">Guyane</option>
        <option value="GF">Guyane française</option>
        <option value="HT">Haïti</option>
        <option value="HN">Honduras</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hongrie</option>
        <option value="BV">Ile Bouvet</option>
        <option value="CX">Ile Christmas</option>
        <option value="NF">Île Norfolk</option>
        <option value="PN">Île Pitcairn</option>
        <option value="AX">Iles Aland</option>
        <option value="KY">Iles Cayman</option>
        <option value="CC">Iles Cocos (Keeling)</option>
        <option value="CK">Iles Cook</option>
        <option value="FO">Îles Féroé</option>
        <option value="HM">Îles Heard-et-MacDonald</option>
        <option value="FK">Îles Malouines</option>
        <option value="MP">Îles Mariannes du Nord</option>
        <option value="MH">Îles Marshall</option>
        <option value="UM">Îles mineures éloignées des États-Unis</option>
        <option value="SB">Îles Salomon</option>
        <option value="TC">Îles Turques-et-Caïques</option>
        <option value="VG">Îles Vierges britanniques</option>
        <option value="VI">Îles Vierges des États-Unis</option>
        <option value="IN">Inde</option>
        <option value="ID">Indonésie</option>
        <option value="IQ">Iraq</option>
        <option value="IE">Irlande</option>
        <option value="IS">Islande</option>
        <option value="IL">Israël</option>
        <option value="IT">Italie</option>
        <option value="LY">Jamahiriya arabe libyenne</option>
        <option value="JM">Jamaïque</option>
        <option value="JP">Japon</option>
        <option value="JO">Jordanie</option>
        <option value="KZ">Kazakhstan</option>
        <option value="KE">Kenya</option>
        <option value="KG">Kirghizistan</option>
        <option value="KI">Kiribati</option>
        <option value="KW">Koweït</option>
        <option value="LS">Lesotho</option>
        <option value="LV">Lettonie</option>
        <option value="LB">Liban</option>
        <option value="LR">Libéria</option>
        <option value="LI">Liechtenstein</option>
        <option value="LT">Lituanie</option>
        <option value="LU">Luxembourg</option>
        <option value="MO">Macao</option>
        <option value="MG">Madagascar</option>
        <option value="MY">Malaisie</option>
        <option value="MW">Malawi</option>
        <option value="MV">Maldives</option>
        <option value="ML">Mali</option>
        <option value="MT">Malte</option>
        <option value="MA">Maroc</option>
        <option value="MQ">Martinique</option>
        <option value="MU">Maurice</option>
        <option value="MR">Mauritanie</option>
        <option value="YT">Mayotte</option>
        <option value="MX">Mexique</option>
        <option value="MC">Monaco</option>
        <option value="MN">Mongolie</option>
        <option value="MS">Montserrat</option>
        <option value="MZ">Mozambique</option>
        <option value="MM">Myanmar</option>
        <option value="NA">Namibie</option>
        <option value="NR">Nauru</option>
        <option value="NP">Népal</option>
        <option value="NI">Nicaragua</option>
        <option value="NE">Niger</option>
        <option value="NG">Nigéria</option>
        <option value="NU">Niué</option>
        <option value="NO">Norvège</option>
        <option value="NC">Nouvelle-Calédonie</option>
        <option value="NZ">Nouvelle-Zélande</option>
        <option value="OM">Oman</option>
        <option value="UG">Ouganda</option>
        <option value="UZ">Ouzbékistan</option>
        <option value="Pakistan">Pakistan</option>
        <option value="Palaos">Palaos</option>
        <option value="Panama">Panama</option>
        <option value="PG">Papouasie-Nouvelle-Guinée</option>
        <option value="Paraguay">Paraguay</option>
        <option value="NL">Pays-Bas</option>
        <option value="Pérou">Pérou</option>
        <option value="Philippines">Philippines</option>
        <option value="Pologne">Pologne</option>
        <option value="PF">Polynésie française</option>
        <option value="PR">Porto Rico</option>
        <option value="Portugal">Portugal</option>
        <option value="TW">Province chinoise de Taiwan</option>
        <option value="Qatar">Qatar</option>
        <option value="SY">République arabe syrienne</option>
        <option value="CF">République centrafricaine</option>
        <option value="KR">République de Corée</option>
        <option value="MD">République de Moldavie</option>
        <option value="CD">République démocratique du Congo</option>
        <option value="DO">République dominicaine</option>
        <option value="IR">République islamique d'Iran</option>
        <option value="KP">République populaire démocratique de Corée</option>
        <option value="LA">République Populaire du Laos</option>
        <option value="CZ">République tchèque</option>
        <option value="TZ">République-Unie de Tanzanie</option>
        <option value="RE">Réunion</option>
        <option value="RO">Roumanie</option>
        <option value="GB">Royaume-Uni</option>
        <option value="RU">Russie</option>
        <option value="RW">Rwanda</option>
        <option value="EH">Sahara occidental</option>
        <option value="KN">Saint-Christophe-et-Niévès</option>
        <option value="SM">Saint-Marin</option>
        <option value="PM">Saint-Pierre-et-Miquelon</option>
        <option value="VA">Saint-Siège (Cité du Vatican)</option>
        <option value="VC">Saint-Vincent-et-les Grenadines</option>
        <option value="SH">Sainte-Hélène</option>
        <option value="LC">Sainte-Lucie</option>
        <option value="WS">Samoa</option>
        <option value="AS">Samoa américaines</option>
        <option value="ST">Sao Tomé-et-Principe</option>
        <option value="SN">Sénégal</option>
        <option value="CS">Serbie-et-Monténégro</option>
        <option value="SC">Seychelles</option>
        <option value="SL">Sierra Leone</option>
        <option value="SG">Singapour</option>
        <option value="SK">Slovaquie</option>
        <option value="SI">Slovénie</option>
        <option value="SO">Somalie</option>
        <option value="SD">Soudan</option>
        <option value="LK">Sri Lanka</option>
        <option value="SE">Suède</option>
        <option value="CH">Suisse</option>
        <option value="SR">Suriname</option>
        <option value="SJ">Svalbard et Jan Mayen</option>
        <option value="SZ">Swaziland</option>
        <option value="TJ">Tadjikistan</option>
        <option value="TD">Tchad</option>
        <option value="IO">Territoire britannique de l'océan Indien</option>
        <option value="TF">Territoire Francais du Sud</option>
        <option value="PS">Territoires palestiniens occupés</option>
        <option value="TH">Thaïlande</option>
        <option value="TL">Timor oriental</option>
        <option value="TG">Togo</option>
        <option value="TK">Tokelau</option>
        <option value="TO">Tonga</option>
        <option value="TT">Trinité-et-Tobago</option>
        <option value="TN">Tunisie</option>
        <option value="TM">Turkménistan</option>
        <option value="TR">Turquie</option>
        <option value="TV">Tuvalu</option>
        <option value="UA">Ukraine</option>
        <option value="UY">Uruguay</option>
        <option value="VU">Vanuatu</option>
        <option value="VE">Vénézuéla</option>
        <option value="VN">Vietnam</option>
        <option value="WF">Wallis-et-Futuna</option>
        <option value="YE">Yémen</option>
        <option value="ZM">Zambie</option>
        <option value="ZW">Zimbabwe</option>
</select>
</div>
</div>

<div class="col-xl-6">
<div class="input_field">
<select class="wide" name="villed" 
value={ville2}
onChange={handleChangeVille2}
>
<option data-display="Delivery city" >{t('VilleDarrive')}</option>
<option drapeau value="Afghanistan">Afghanistan</option>
<option drapeau value="Afrique">Afrique du Sud</option>
<option drapeau value="Albanie">Albanie</option>
<option drapeau value="DAlgérieZ">Algérie</option>
<option drapeau value="Allemagne">Allemagne</option>
<option drapeau value="yougoslave de Macédoine">Ancienne République yougoslave de Macédoine</option>
<option drapeau value="Andorre">Andorre</option>
<option drapeau value="Angola">Angola</option>
<option drapeau value="Anguilla">Anguilla</option>
<option drapeau value="Antarctique">Antarctique</option>
<option drapeau value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
<option drapeau value="Antilles">Antilles néerlandaises</option>
<option drapeau value="saoudite">Arabie saoudite</option>
<option drapeau value="Argentine">Argentine</option>
<option drapeau value="Arménie">Arménie</option>
<option drapeau value="Aruba">Aruba</option>
<option drapeau value="Australie">Australie</option>
<option drapeau value="Autriche">Autriche</option>
<option drapeau value="Azerbaïdjan">Azerbaïdjan</option>
<option drapeau value="Bahamas">Bahamas</option>
<option drapeau value="Bahreïn">Bahreïn</option>
<option drapeau value="Bangladesh">Bangladesh</option>
<option drapeau value="Barbade">Barbade</option>
<option drapeau value="Belgique">Belgique</option>
<option drapeau value="Belize">Belize</option>
<option drapeau value="Bénin">Bénin</option>
<option drapeau value="Bermudes">Bermudes</option>
<option drapeau value="Bhoutan">Bhoutan</option>
<option drapeau value="Biélorussie">Biélorussie</option>
<option drapeau value="Bolivie">Bolivie</option>
<option drapeau value="Bosnie-et-Herzégovine">Bosnie-et-Herzégovine</option>
<option drapeau value="Botswana">Botswana</option>
<option drapeau value="Brésil">Brésil</option>
<option drapeau value="Brunei">Brunei Darussalam</option>
<option drapeau value="Bulgarie">Bulgarie</option>
<option drapeau value="BurkinaFaso">Burkina Faso</option>
<option drapeau value="Burundi">Burundi</option>
<option drapeau value="Cambodge">Cambodge</option>
<option drapeau value="Cameroun">Cameroun</option>
<option drapeau value="Canada">Canada</option>
<option drapeau value="Cap-Vert">Cap-Vert</option>
<option drapeau value="Chili">Chili</option>
<option drapeau value="Chine">Chine</option>
<option drapeau value="Chypre">Chypre</option>
<option drapeau value="Colombie">Colombie</option>
<option drapeau value="Comores">Comores</option>
<option drapeau value="Congo">Congo</option>
<option drapeau value="Costa Rica">Costa Rica</option>
<option drapeau value="CCôte d'IvoireI">Côte d'Ivoire</option>
<option drapeau value="Croatie">Croatie</option>
<option drapeau value="Cuba">Cuba</option>
<option drapeau value="Danemark">Danemark</option>
<option drapeau value="Djibouti">Djibouti</option>
<option drapeau value="Dominique">Dominique</option>
<option drapeau value="Égypte">Égypte</option>
<option drapeau value="SV">El Salvador</option>
<option drapeau value="AE">Émirats arabes unis</option>
<option drapeau value="EC">Équateur</option>
<option drapeau value="ER">Érythrée</option>
<option drapeau value="ES">Espagne</option>
<option drapeau value="EE">Estonie</option>
<option drapeau value="FM">États fédérés de Micronésie</option>
<option drapeau value="US">États-Unis</option>
<option drapeau value="ET">Éthiopie</option>
<option drapeau value="FJ">Fidji</option>
<option drapeau value="FI">Finlande</option>
<option drapeau value="FR">France</option>
<option drapeau value="GA">Gabon</option>
<option drapeau value="GM">Gambie</option>
<option drapeau value="GE">Géorgie</option>
<option drapeau value="GS">Géorgie du Sud-et-les Îles Sandwich du Sud</option>
<option drapeau value="GH">Ghana</option>
<option drapeau value="GI">Gibraltar</option>
<option drapeau value="GR">Grèce</option>
<option drapeau value="GD">Grenade</option>
<option drapeau value="GL">Groenland</option>
<option drapeau value="GP">Guadeloupe</option>
<option drapeau value="GU">Guam</option>
<option drapeau value="GT">Guatemala</option>
<option drapeau value="GN">Guinée</option>
<option drapeau value="GQ">Guinée équatoriale</option>
<option drapeau value="GW">Guinée-Bissau</option>
<option drapeau value="GY">Guyane</option>
<option drapeau value="GF">Guyane française</option>
<option drapeau value="HT">Haïti</option>
<option drapeau value="HN">Honduras</option>
<option drapeau value="HK">Hong Kong</option>
<option drapeau value="HU">Hongrie</option>
<option drapeau value="BV">Ile Bouvet</option>
<option drapeau value="CX">Ile Christmas</option>
<option drapeau value="NF">Île Norfolk</option>
<option drapeau value="PN">Île Pitcairn</option>
<option drapeau value="AX">Iles Aland</option>
<option drapeau value="KY">Iles Cayman</option>
<option drapeau value="CC">Iles Cocos (Keeling)</option>
<option drapeau value="CK">Iles Cook</option>
<option drapeau value="FO">Îles Féroé</option>
<option drapeau value="HM">Îles Heard-et-MacDonald</option>
<option drapeau value="FK">Îles Malouines</option>
<option drapeau value="MP">Îles Mariannes du Nord</option>
<option drapeau value="MH">Îles Marshall</option>
<option drapeau value="UM">Îles mineures éloignées des États-Unis</option>
<option drapeau value="SB">Îles Salomon</option>
<option drapeau value="TC">Îles Turques-et-Caïques</option>
<option drapeau value="VG">Îles Vierges britanniques</option>
<option drapeau value="VI">Îles Vierges des États-Unis</option>
<option drapeau value="IN">Inde</option>
<option drapeau value="ID">Indonésie</option>
<option drapeau value="IQ">Iraq</option>
<option drapeau value="IE">Irlande</option>
<option drapeau value="IS">Islande</option>
<option drapeau value="IL">Israël</option>
<option drapeau value="IT">Italie</option>
<option drapeau value="LY">Jamahiriya arabe libyenne</option>
<option drapeau value="JM">Jamaïque</option>
<option drapeau value="JP">Japon</option>
<option drapeau value="JO">Jordanie</option>
<option drapeau value="KZ">Kazakhstan</option>
<option drapeau value="KE">Kenya</option>
<option drapeau value="KG">Kirghizistan</option>
<option drapeau value="KI">Kiribati</option>
<option drapeau value="KW">Koweït</option>
<option drapeau value="LS">Lesotho</option>
<option drapeau value="LV">Lettonie</option>
<option drapeau value="LB">Liban</option>
<option drapeau value="LR">Libéria</option>
<option drapeau value="LI">Liechtenstein</option>
<option drapeau value="LT">Lituanie</option>
<option drapeau value="LU">Luxembourg</option>
<option drapeau value="MO">Macao</option>
<option drapeau value="MG">Madagascar</option>
<option drapeau value="MY">Malaisie</option>
<option drapeau value="MW">Malawi</option>
<option drapeau value="MV">Maldives</option>
<option drapeau value="ML">Mali</option>
<option drapeau value="MT">Malte</option>
<option drapeau value="MA">Maroc</option>
<option drapeau value="MQ">Martinique</option>
<option drapeau value="MU">Maurice</option>
<option drapeau value="MR">Mauritanie</option>
<option drapeau value="YT">Mayotte</option>
<option drapeau value="MX">Mexique</option>
<option drapeau value="MC">Monaco</option>
<option drapeau value="MN">Mongolie</option>
<option drapeau value="MS">Montserrat</option>
<option drapeau value="MZ">Mozambique</option>
<option drapeau value="MM">Myanmar</option>
<option drapeau value="NA">Namibie</option>
<option drapeau value="NR">Nauru</option>
<option drapeau value="NP">Népal</option>
<option drapeau value="NI">Nicaragua</option>
<option drapeau value="NE">Niger</option>
<option drapeau value="NG">Nigéria</option>
<option drapeau value="NU">Niué</option>
<option drapeau value="NO">Norvège</option>
<option drapeau value="NC">Nouvelle-Calédonie</option>
<option drapeau value="NZ">Nouvelle-Zélande</option>
<option drapeau value="OM">Oman</option>
<option drapeau value="UG">Ouganda</option>
<option drapeau value="UZ">Ouzbékistan</option>
<option drapeau value="PK">Pakistan</option>
<option drapeau value="PW">Palaos</option>
<option drapeau value="PA">Panama</option>
<option drapeau value="PG">Papouasie-Nouvelle-Guinée</option>
<option drapeau value="PY">Paraguay</option>
<option drapeau value="NL">Pays-Bas</option>
<option drapeau value="PE">Pérou</option>
<option drapeau value="PH">Philippines</option>
<option drapeau value="PL">Pologne</option>
<option drapeau value="PF">Polynésie française</option>
<option drapeau value="PR">Porto Rico</option>
<option drapeau value="PT">Portugal</option>
<option drapeau value="TW">Province chinoise de Taiwan</option>
<option drapeau value="QA">Qatar</option>
<option drapeau value="SY">République arabe syrienne</option>
<option drapeau value="CF">République centrafricaine</option>
<option drapeau value="KR">République de Corée</option>
<option drapeau value="MD">République de Moldavie</option>
<option drapeau value="CD">République démocratique du Congo</option>
<option drapeau value="DO">République dominicaine</option>
<option drapeau value="IR">République islamique d'Iran</option>
<option drapeau value="KP">République populaire démocratique de Corée</option>
<option drapeau value="LA">République Populaire du Laos</option>
<option drapeau value="CZ">République tchèque</option>
<option drapeau value="TZ">République-Unie de Tanzanie</option>
<option drapeau value="RE">Réunion</option>
<option drapeau value="RO">Roumanie</option>
<option drapeau value="GB">Royaume-Uni</option>
<option drapeau value="RU">Russie</option>
<option drapeau value="RW">Rwanda</option>
<option drapeau value="EH">Sahara occidental</option>
<option drapeau value="KN">Saint-Christophe-et-Niévès</option>
<option drapeau value="SM">Saint-Marin</option>
<option drapeau value="PM">Saint-Pierre-et-Miquelon</option>
<option drapeau value="VA">Saint-Siège (Cité du Vatican)</option>
<option drapeau value="VC">Saint-Vincent-et-les Grenadines</option>
<option drapeau value="SH">Sainte-Hélène</option>
<option drapeau value="LC">Sainte-Lucie</option>
<option drapeau value="WS">Samoa</option>
<option drapeau value="AS">Samoa américaines</option>
<option drapeau value="ST">Sao Tomé-et-Principe</option>
<option drapeau value="SN">Sénégal</option>
<option drapeau value="CS">Serbie-et-Monténégro</option>
<option drapeau value="SC">Seychelles</option>
<option drapeau value="SL">Sierra Leone</option>
<option drapeau value="SG">Singapour</option>
<option drapeau value="SK">Slovaquie</option>
<option drapeau value="SI">Slovénie</option>
<option drapeau value="SO">Somalie</option>
<option drapeau value="SD">Soudan</option>
<option drapeau value="LK">Sri Lanka</option>
<option drapeau value="SE">Suède</option>
<option drapeau value="CH">Suisse</option>
<option drapeau value="SR">Suriname</option>
<option drapeau value="SJ">Svalbard et Jan Mayen</option>
<option drapeau value="SZ">Swaziland</option>
<option drapeau value="TJ">Tadjikistan</option>
<option drapeau value="TD">Tchad</option>
<option drapeau value="IO">Territoire britannique de l'océan Indien</option>
<option drapeau value="TF">Territoire Francais du Sud</option>
<option drapeau value="PS">Territoires palestiniens occupés</option>
<option drapeau value="TH">Thaïlande</option>
<option drapeau value="TL">Timor oriental</option>
<option drapeau value="TG">Togo</option>
<option drapeau vallue="TN">Tunisie</option>
<option drapeau value="TM">Turkménistan</option>
<option drapeau value="TR">Turquie</option>
<option drapeau value="TV">Tuvalu</option>
<option drapeau value="UA">Ukraine</option>
<option drapeau value="UY">Uruguay</option>
<option drapeau value="VU">Vanuatu</option>
<option drapeau value="VE">Vénézuéla</option>
<option drapeau value="VN">Vietnam</option>
<option drapeau value="WF">Wallis-et-Futuna</option>
<option drapeau value="YE">Yémen</option>
<option drapeau value="ZM">Zambie</option>
<option drapeau value="ZW">Zimbabwe</option>
</select>
</div>
</div>
<div class="col-xl-12">
<div class="input_field">
<textarea name="message"placeholder="Message"

value={Message}
onChange={handleChangeMessage}
></textarea>
</div>
</div>
<div class="col-xl-12">
<div class="input_field">
<button class="boxed-btn3-line" type="submit">{t('btnEstimation')}</button>
</div>
</div>
</div>
</form>
{feedback && <p>{feedback}</p>}
</div>
</div>
</div>
</div>
</div>

</div>
  );
};
export default Estimate;