import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
export const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_9q108me', 'template_rahvpq5', form.current, {
      publicKey: '3X-lNeQJ0v10XAiJ4',
    })
    .then(
      (result) => {
        console.log(result.text);
        console.log('SUCCESS!');
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
  };
  const { t } = useTranslation();
  return (
    <div>
<footer class="footer">
<div class="footer_top">
<div class="container">
<div class="row">
<div class="col-xl-3 col-md-6 col-lg-3">
<div class="footer_widget">
<h3 class="footer_title" >
Services
</h3>
<ul>
<li><a href="/services?service=fret-maritime" datal-i18n="titleservice1">{t('Fret maritime')}</a></li>
<li><a href="/services?service=fret-aerien" datal-i18n="titleservice2">{t('Fret Aerien')}</a></li>
<li><a href="/services?service=terrestre" datal-i18n="titleservice3">{t('terrestre')}</a></li>
<li><a href="/services?service=douane" datal-i18n="titleservice4">{t('Douane')}</a></li>

</ul>
</div>
</div>
<div class="col-xl-2 col-md-6 col-lg-2">
<div class="footer_widget">
<h3 class="footer_title">
    {t('Entreprise')}
</h3>
<ul>
<li><a href="/about"  datal-i18n="about">
{t('About')}</a></li>
<li><a href="/contact" datal-i18n="contact">Contact</a></li>


</ul>
</div>
</div>

<div class="col-xl-6 col-md-6 col-lg-6">
<div class="footer_widget">
<h3 class="footer_title" datal-i18n="btnabonner">
  {t('Subscribe')}
</h3>

<form ref={form} onSubmit={sendEmail}class="newsletter_form"  >
    <input  name="from_email" type="text"  placeholder={t('Enter your mail')} required/>
    <button type="submit">{t('Subscribe')}</button>
    </form>
<p class="newsletter_text"  datal-i18n="textfooter">{t('textfooter')}</p>
</div>
</div>
</div>
</div>
</div>
<div class="copy-right_text">
<div class="container">
<div class="footer_border"></div>
<div class="row">
<div class="col-xl-12">
<p class="copy_right text-center">



</p>
</div>
</div>
</div>
</div>
</footer>
    </div>
  );
};
export default Footer;