import React, {useState} from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });

function Contact(){
    const { t } = useTranslation();
    return(
<div>

<div class="contact_action_area">
<div class="container">
<div class="row align-items-center">
<div class="col-xl-7 col-md-6">
<div class="action_heading">
<h3 datal-i18n="sécurisé"> 100% {t('sécurisé')} </h3>
<p></p>
</div>
</div>
<div class="col-xl-5 col-md-6">
<div class="call_add_action">

<a href="tel:+21671449033" class="boxed-btn3">
                            <i className="fa fa-phone"></i> (+216) 71 449 033
                          </a>
</div>
</div>
</div>
</div>
</div>

</div>
    
     )

}
export default Contact;