import React, {useState} from "react";
import logo from '../img/logo.png';

import address from '../img/icon/address.svg';
import support from '../img/icon/support.svg';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use (HttpApi)
.init({

    
   
    detection: {

      order: ['htmlTag','cookie',  'localStorage', 'sessionStorage', 'navigator',  'path', 'subdomain'],
      caches:["cookie"],
    },
    backend:{
      loadPath: '/locale/{{lng}}/translation.json',


    }
  

  });
function Location(){
    const { t } = useTranslation();
    return(
<div>

<div class="contact_location">
<div class="container">
<div class="row">
<div class="col-xl-6 col-lg-6 col-md-6">
<div class="location_left">
<div class="logo">
<a href="/">
<img src={logo} alt="logo" />
</a>
</div>
<ul>


</ul>
</div>
</div>
<div class="col-xl-3 col-md-3">
<div class="single_location">

<h3> <img src={address} alt="" />{t('Adresse')} </h3>
<p>01 RUE HEDI NOUIRA IMMB JUINI 55 
2040 RADES SALINE
</p>
</div>
</div>
<div class="col-xl-3 col-md-3">
<div class="single_location">
<h3> <img src={support} alt="support" />Contact</h3>
<p >(+216) 71 449 033 <br/>
contact@itts.com.tn</p>
</div>
</div>
</div>
</div>
</div>
</div>
    
     )

}
export default Location;