import React from 'react';
import ReactDOM from 'react-dom/client';


import './css/style.css';

import './css/owl.carousel.min.css';

import"./css/font-awesome.min.css";
import"./css/themify-icons.css";





import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
