import React, { useEffect } from 'react';
import { BrowserRouter, Routes,Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";
import About_us from "./Pages/About_us";
function App() {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "./js/main.js";
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="App">
<BrowserRouter>
<Navbar/>
<Routes>
  <Route index element ={<Home/>}/>
  <Route path="/services" element={<Services/>}></Route>
  <Route path="/About"element ={<About_us/>} />
  <Route path="/contact"element ={<Contact/>} />
</Routes>
</BrowserRouter>
    </div>
  );
}

export default App;
